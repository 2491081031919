var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticClass: "mb whitebg",
      attrs: { align: "middle", id: "propos", justify: "center", type: "flex" }
    },
    [
      _c(
        "a-col",
        {
          staticClass: "mb my-blue",
          attrs: { md: { span: 14, offset: 2 }, xs: { span: 22, offset: 1 } }
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.propos) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }