<template>
  <a-row
    align="middle"
    id="propos"
    class="mb whitebg"
    justify="center"
    type="flex"
  >
    <a-col
      :md="{ span: 14, offset: 2 }"
      :xs="{ span: 22, offset: 1 }"
      class="mb my-blue"
    >
      <span v-html="propos"> </span>
    </a-col>
  </a-row>
</template>
<script>
export default {
    props : {
        propos : { type :String, default : () => ''}
    }
}
</script>